import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

const MenuItem = ({ item, index, activateIndex, onSelectItem }) => (
  <li
    className={classNames({
      'video-react-menu-item': true,
      'video-react-selected': index === activateIndex,
    })}
    role="presentation"
    onClick={() => onSelectItem(index)}
  >
    {item.label}
    <span className="video-react-control-text" />
  </li>
)

MenuItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  activateIndex: PropTypes.number,
  onSelectItem: PropTypes.func,
}

export default MenuItem
