/* eslint-disable no-underscore-dangle */
import 'whatwg-fetch'
import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import enableInlineVideo from 'iphone-inline-video'
import shuffle from 'lodash/shuffle'
import * as Sentry from '@sentry/browser'
import {
  Player,
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  TimeDivider,
  DurationDisplay,
  VolumeMenuButton,
  ProgressControl,
  Shortcut,
  FullscreenToggle,
} from 'video-react'
import 'video-react/dist/video-react.css'
import Hls from 'hls.js'
import SocketIO from 'socket.io-client'
import mux from 'mux-embed'

import ControlButton from 'components/ControlButton'
import notifyParent from 'utils/notifyParent'
// import Notify from 'components/Notify'
import QuestionNavigation from 'components/QuestionNavigation'
import AnnotationPreviewWrapper from 'components/AnnotationPreviewWrapper'
import AnnotationPreview from 'components/AnnotationPreview'
import VideoQualityMenuButton from 'components/VideoQualityMenuButton'
import VideoPlaybackRateMenuButton from 'components/VideoPlaybackRateMenuButton'
import PlayToggle from 'components/PlayToggle'
import FooterButtons from 'components/FooterButtons'
import Toast from '../../components/Toast'
import Header from '../../components/Header'
import EndMessage from '../../components/EndMessage'
import apm from '../../utils/apm'
import './style.css'

const cloneDeep = (o) => JSON.parse(JSON.stringify(o))
const isMobile =
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1

const timeUpdateDiff = 0.37 // ~0.36xx was the max time diff when ontimeupdate events were fired

const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
  return null
}

// eslint-disable-next-line no-unused-vars
const randomize = (options) => {
  const skipShuffle = options.filter(({ content }) =>
    /True|False|All of the above/.test(content),
  )

  return skipShuffle.length ? options : shuffle(options)
}

const resizePlayer = () => {
  const videoPlayer = document.querySelector('.video-react-controls-enabled')
  videoPlayer.style.height = '100vh'
  videoPlayer.style.width = '100vw'
}

const playbackRates = [2, 1.5, 1.25, 1, 0.75, 0.5]

const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(window.atob(base64))
}

export class EmbedPage extends React.Component {
  state = {
    error: '',
    streamingUrl: '',
    thumbnail: '',
    canSeek: false,
    isWorkedOut: false,
    time: 0,
    preview: {},
    answers: {},
    warning: '',
    embedToken: getQueryVariable('token'),
    videoQualities: [],
    endMessage: false,
    bottomText: false,
    isFullscreen: false,
    endTimeLine: 0,
    answeredQuestions: [],
    unAnsweredQuestions: [],
    showQuestionNav: false,
    finished: false,
    answerData: [],
  }

  componentDidMount() {
    this.initSocket()

    document.body.style.overflow = 'hidden'

    const { eventKey } = this.supportedVisibilityChangeEvent()
    document.addEventListener(eventKey, this.appFocusHandler)
    window.addEventListener('message', this.messageHandler)
  }

  componentWillUnmount() {
    const { eventKey } = this.supportedVisibilityChangeEvent()

    this.socket.off('EMBED_DATA')
    this.socket.off('EMBED_ERROR')
    this.socket.off('REDIRECT_DATA')
    this.socketSpan.end()

    document.removeEventListener(eventKey, this.appFocusHandler)
  }

  componentDidCatch(error, info) {
    notifyParent({ type: 'FULLSCREEN', isFullscreen: false })
    Sentry.captureException(error)
    Sentry.captureMessage(info)
    apm.captureException(error)
  }

  initSocket = () => {
    this.socketSpan = apm.startSpan(
      `socket.io client ${process.env.WS_URL}`,
      'ws',
    )
    this.socket = SocketIO(process.env.WS_URL, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1 * 1000,
      reconnectionDelayMax: 10 * 1000,
      autoConnect: true,
      transports: ['websocket'],
      upgrade: false,
    })

    this.socket.on('disconnect', () => notifyParent({ type: 'DISCONNECTED' }))

    this.socket.on('error', (err) => {
      Sentry.captureMessage(err)
      apm.captureException(err)
    })

    this.socket.on('reconnect', (data) => console.log('reconnect', data))

    this.socket.on('connect', () => {
      const { embedToken } = this.state

      this.socket.on('LISTENING', () => {
        const { streamingUrl } = this.state
        if (!streamingUrl)
          this.socket.emit('EMBED_START', {
            embedToken,
            apmTraceparent: apm.getCurrentTransaction(),
          })
      })
      this.socket.on(
        'EMBED_DATA',
        ({
          streamingUrl,
          thumbnail,
          canSeek,
          isWorkedOut,
          controlBars,
          time,
          playbackRate,
          annotations,
          title,
          timeLine,
          prevAnswers,
          prevInteractions,
        }) => {
          if (!timeLine || (timeLine && !timeLine.length)) {
            this.setState({
              startTimeLine: 0,
            })
          } else {
            this.setState({
              hasPrevTimeLine: true,
            })
          }
          const { params } = parseJwt(embedToken)
          apm.addLabels({ owner: params.owner, videoId: params.videoId })
          apm.setUserContext({ id: params.viewer })
          const formattedPrevAnswer = {}
          if (prevAnswers && prevAnswers.length) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < prevAnswers.length; i++) {
              // loop through saved answers to set in answered state array
              const { item, annotation: annotationId } = prevAnswers[i]
              const { answeredQuestions } = this.state

              // find the annotations from all annotations
              const filterAnnotation = annotations.find(
                (annotation) => annotation._id === prevAnswers[0].annotation,
              )

              // use findIndex to get the page for question navigation
              const page = filterAnnotation.items.findIndex(
                ({ _id: id }) => id === item,
              )
              const setPrevAnswers = {
                questionId: item,
                annotationId,
                page: page + 1,
                tag: 'ans',
              }

              answeredQuestions.push(setPrevAnswers)
            }

            // format the previously answered questions according to the state format
            const groupByAnnotation = groupBy(prevAnswers, 'annotation')
            Object.keys(groupByAnnotation).map((aId) => {
              const newItem = {}
              const items = groupBy(groupByAnnotation[aId], 'item')
              Object.keys(items).forEach((iteem) => {
                const val = []
                items[iteem].forEach((i) => {
                  // need forEach for MCQ
                  val.push({ value: i.option, duration: i.duration })
                })
                const itemObj = {
                  values: val,
                }
                newItem[iteem] = itemObj
              })
              formattedPrevAnswer[aId] = newItem
              return formattedPrevAnswer
            })
            this.setState({ answers: formattedPrevAnswer })
          }

          const endOfLessonQuiz =
            typeof controlBars.hasEndOfLessonQuiz !== 'undefined'
              ? controlBars.hasEndOfLessonQuiz
              : true

          this.setState({
            streamingUrl,
            thumbnail,
            canSeek:
              typeof canSeek !== 'undefined' ? canSeek : controlBars.seekBar,
            hasEndOfLessonQuiz: endOfLessonQuiz,
            isWorkedOut,
            finished: prevInteractions && isWorkedOut,
            time,
            playbackRate,
            annotations: sortBy(annotations, 'startTime'),
            title,
            disableControls: true,
            timeLine: timeLine || [],
            viewer: +params.viewer,
          })

          const onHlsReady = () => {
            if (this.hls) {
              this.setState({
                videoQualities: this.hls.levels.map(({ height }) => height),
              })
            } else {
              this.videoEl.pause()
            }

            setTimeout(() => {
              if (time) {
                this.videoEl.currentTime = time
                this.videoEl.play()
              }

              this.setState({
                disableControls: false,
              })
              notifyParent({ type: 'VIDEO_READY' })
            }, 500)
          }

          if (Hls.isSupported()) {
            this.hls = new Hls()
            this.hls.loadSource(streamingUrl)
            this.hls.attachMedia(this.videoEl)
            this.hls.on(Hls.Events.MANIFEST_PARSED, onHlsReady)
          } else if (
            this.videoEl.canPlayType('application/vnd.apple.mpegurl')
          ) {
            this.videoEl.src = streamingUrl
            this.videoEl.addEventListener('loadedmetadata', onHlsReady)
          }

          if (playbackRate && this.videoEl) {
            this.videoEl.playbackRate = playbackRate
          }

          window.addEventListener('resize', resizePlayer)
          window.addEventListener('orientationchange', resizePlayer)
        },
      )

      this.socket.on('EMBED_ERROR', ({ name, message: error }) => {
        notifyParent({ type: 'FULLSCREEN', isFullscreen: false })
        let errorMessage
        if (name === 'DuplicateConnection') {
          if (this.videoEl) this.videoEl.pause()
          errorMessage =
            'A duplicate connection has been detected. You might have opened the same video on other window or device, please continue there.'
          this.setState({
            streamingUrl: false,
            disableControls: false,
            error: errorMessage,
          })
        } else if (name === 'JsonWebTokenError') {
          errorMessage = 'You must provide a valid embed token.'
          this.setState({
            error: errorMessage,
          })
        } else {
          errorMessage =
            name === 'TokenExpiredError'
              ? 'Video embed token has expired. Please refresh the page.'
              : error
          this.setState({
            error: errorMessage,
          })

          notifyParent({ type: 'VIDEO_ERROR' })
        }

        apm.captureError(errorMessage)
      })

      this.socket.on('REDIRECT_DATA', ({ videoId, time }) => {
        notifyParent({ type: 'VIDEO_REDIRECT', value: videoId, time })
      })
    })
  }

  messageHandler = ({ origin, data }) => {
    const { embedToken, finished, endMessage } = this.state
    if (typeof data !== 'string') return
    if (
      /^http.{3,25}(localhost:\d{4}|innovatetech.io|adpeda.com|advancedpedagogy.com|mysecondteacher.com)$/i.test(
        origin,
      )
    ) {
      const parsedData = JSON.parse(data)
      if (parsedData.type === 'END_OF_LESSON_QUIZ') {
        const endOfLessonQuiz = this.state.annotations.find(
          (annotation) => annotation.items.length > 4,
        )

        if (endOfLessonQuiz) {
          if (finished && endMessage) {
            this.socket.emit('CREATE_NEW_INTERACTION', {
              embedToken,
            })

            setTimeout(() => {
              this.setState({
                endMessage: false,
                finished: false,
                timeLine: [
                  {
                    startTimeLine: 0,
                    endTimeLine: endOfLessonQuiz.startTime,
                  },
                ],
                disableControls: false,
                answers: {},
                answeredQuestions: [],
                unAnsweredQuestions: [],
                answerData: [],
              })
            }, 0)

            this.socket.on('NEW_INTERACTION_STARTED', () => {
              setTimeout(() => {
                this.videoEl.currentTime = endOfLessonQuiz.startTime - 0.35
                this.videoEl.play()
              }, 0)
            })
          } else {
            setTimeout(() => {
              this.videoEl.currentTime = endOfLessonQuiz.startTime - 0.35
              this.videoEl.play()
            }, 0)
          }
        }
      }
    }
  }

  supportedVisibilityChangeEvent = () =>
    [
      { stateKey: 'hidden', eventKey: 'visibilitychange' },
      { stateKey: 'webkitHidden', eventKey: 'webkitvisibilitychange' },
      { stateKey: 'mozHidden', eventKey: 'mozvisibilitychange' },
      { stateKey: 'msHidden', eventKey: 'msvisibilitychange' },
    ].find(({ stateKey: key }) => key in document)

  appFocusHandler = () => {
    const { stateKey } = this.supportedVisibilityChangeEvent()
    const isBlur = document[stateKey]
    if (isBlur && this.videoEl && !this.videoEl.paused) {
      this.videoEl.pause()
    }
  }

  onPlayerLoad = (ref) => {
    this.videoEl = document.querySelector('video')

    if (!ref || !this.videoEl) return

    this.player = ref
    this.setState({ duration: this.videoEl.duration })

    this.videoEl.addEventListener(
      'playing',
      () => {
        if (isMobile) {
          this.player.toggleFullscreen()
          notifyParent({
            type: 'FULLSCREEN',
            isFullscreen: true,
          })
        }
        notifyParent({ type: 'VIDEO_PLAYED' })
      },
      { once: true },
    )

    const { embedToken, playbackRate } = this.state
    const playerInitTime = Date.now()

    if (isMobile) {
      document.addEventListener(
        'touchmove',
        (event) => {
          if (event.scale !== 1) {
            event.preventDefault()
          }
        },
        false,
      )
      this.videoEl.setAttribute('playsinline', true)
      enableInlineVideo(this.videoEl)
    }

    this.videoEl.addEventListener('contextmenu', (e) => e.preventDefault())

    this.player.subscribeToStateChange((playerState, prevPlayerState) => {
      this.setState({
        playbackRate: playerState.playbackRate || 1,
        isFullscreen: playerState.isFullscreen,
      })

      const { seekingTime } = playerState

      if (seekingTime !== 0) {
        this.setState({
          prevCurrentTime: prevPlayerState.currentTime,
        })
      }
    })

    if (playbackRate) this.videoEl.playbackRate = playbackRate
    let previousUpdateTime = 0
    this.videoEl.ontimeupdate = ({ target }) => {
      const {
        ignoreAnnotationId,
        playbackRate: rate,
        finished,
        canSeek,
        startTimeLine,
        hasPrevTimeLine,
        timeLine,
        prevCurrentTime,
      } = this.state
      const previousPreview = cloneDeep(this.state.preview)
      const annotations = cloneDeep(this.state.annotations)

      const { currentTime } = target

      const annotationCount = annotations.length
      const padding = (timeUpdateDiff * rate) / 2
      let timeUpdated = false
      let preview = {}

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < annotationCount; i++) {
        const annotation = annotations[i]
        const { _id, startTime: st, endTime: et, pause, ...rest } = annotation

        // eslint-disable-next-line no-underscore-dangle
        const alreadyInPreview = previousPreview[_id]
        const startTime = st - padding
        const endTime = et || st + padding
        const shouldPreview =
          _id !== ignoreAnnotationId &&
          currentTime >= startTime &&
          currentTime <= endTime

        if (shouldPreview) {
          if (pause && !this.videoEl.paused) this.videoEl.pause()

          if (!timeUpdated && !finished) {
            this.socket.emit('INTERACTION', {
              action: 'TIME',
              time: startTime,
              timeLine,
              embedToken,
              apmTraceparent: apm.getCurrentTransaction(),
            })
            timeUpdated = true
          }

          preview = {
            ...preview,
            [_id]: alreadyInPreview || {
              ...rest,
              _id,
              rendered: true,
              startTime,
              endTime: et,
              pause,
              number: 1,
            },
          }
        }
      }

      if (!finished) {
        if (timeLine.length) {
          if (!Object.keys(preview).length && hasPrevTimeLine) {
            this.setState({
              startTimeLine: timeLine[timeLine.length - 1].endTimeLine,
              endTimeLine: currentTime,
            })
          } else {
            this.setState({
              endTimeLine: currentTime,
            })
          }
        } else {
          this.setState({
            startTimeLine: 0,
            endTimeLine: currentTime,
          })
        }
      }

      this.setState({
        preview,
        ignoreAnnotationId: undefined,
        disableControls: this.controlsShouldBeDisabled(preview),
      })

      const fiveSeconds = !timeUpdated && currentTime - previousUpdateTime >= 5

      if (fiveSeconds && currentTime > 1 && !finished) {
        previousUpdateTime = currentTime
        this.socket.emit('INTERACTION', {
          action: 'TIME',
          time: currentTime,
          timeLine: [...timeLine, { startTimeLine, endTimeLine: currentTime }],
          embedToken,
          apmTraceparent: apm.getCurrentTransaction(),
        })
      }

      if (canSeek && finished && prevCurrentTime !== 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < timeLine.length; i++) {
          if (
            currentTime >= timeLine[i].startTimeLine &&
            currentTime <= timeLine[i].endTimeLine
          ) {
            // if the seeked time is inside timeline
            this.videoEl.currentTime = currentTime
            this.videoEl.play()
            break
          } else {
            // if the seeked time not inside timeLine
            if (currentTime > prevCurrentTime) {
              // if seeked forward
              if (currentTime - timeLine[i].startTimeLine < 0) {
                this.videoEl.currentTime = timeLine[i].startTimeLine
                this.videoEl.play()
                break
              }
            }
            if (currentTime < prevCurrentTime) {
              // if seeked back
              if (timeLine[i].endTimeLine - currentTime > 0) {
                let index = 0
                if (i > 0) {
                  index = i - 1
                }
                this.videoEl.currentTime = timeLine[index].endTimeLine - 2
                this.videoEl.play()
                break
              }
            }
          }
        }
        this.setState({
          prevCurrentTime: 0,
        })

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < timeLine.length; i++) {
          if (
            currentTime >= timeLine[i].startTimeLine &&
            currentTime <= timeLine[i].endTimeLine
          ) {
            break
          } else if (
            !Object.keys(preview).length &&
            currentTime - timeLine[i].startTimeLine < 0
          ) {
            this.videoEl.currentTime = timeLine[i].startTimeLine
            this.videoEl.play()
            break
          }
        }
      }

      if (prevCurrentTime !== 0 && !finished) {
        this.socket.emit('INTERACTION', {
          action: 'TIME',
          time: currentTime,
          timeLine: [{ startTimeLine: currentTime, endTimeLine: currentTime }],
          embedToken,
          apmTraceparent: apm.getCurrentTransaction(),
        })
      }

      if (!finished && currentTime < prevCurrentTime && prevCurrentTime !== 0) {
        const searchForObjectInTimeLine = timeLine.find(
          (t) => t.startTimeLine <= currentTime && t.endTimeLine >= currentTime,
        )

        const filterTimeLine = timeLine.filter(
          (t) => t.startTimeLine <= currentTime,
        )

        const removeTimeIfInside = filterTimeLine.filter(
          (ftl) =>
            ftl.startTimeLine <= currentTime && ftl.endTimeLine <= currentTime,
        )

        let newTimeLine = [
          ...removeTimeIfInside,
          { startTimeLine: currentTime, endTimeLine: currentTime },
        ]

        if (typeof searchForObjectInTimeLine === 'object') {
          newTimeLine = [
            ...newTimeLine,
            {
              startTimeLine: searchForObjectInTimeLine.startTimeLine,
              endTimeLine: currentTime,
            },
          ]
        }

        this.setState({
          timeLine: sortBy(newTimeLine),
          prevCurrentTime: 0,
          startTimeLine: currentTime,
        })
      }
    }

    this.videoEl.onended = () => this.onVideoEnded()

    const { params } = parseJwt(embedToken)

    mux.monitor('video', {
      debug: false,
      respectDoNotTrack: false, // Track browsers even where Do Not Track is enabled
      data: {
        env_key: process.env.MUX_DATA_KEY,
        viewer_user_id: params.viewer,
        video_id: params.videoId,
        player_name: 'IVy Embed Player',
        player_init_time: playerInitTime,
      },
    })
  }

  fetchRetry = (url, options, n, embedToken) => {
    const { answerData } = this.state
    const postAnswersSpan = apm.startSpan(`POST ${url}`, 'http')
    fetch(url, options)
      .then((response) => {
        if (answerData.length > 4 && response) {
          this.onVideoEnded(true)
        }
      })
      .catch((error) => {
        if (n !== 1) {
          this.fetchRetry(
            url,
            { ...options, body: answerData },
            n - 1,
            embedToken,
          )
        } else {
          const { params } = parseJwt(embedToken)
          Sentry.withScope((scope) => {
            scope.setExtra('viewer', params.viewer)
            scope.setExtra('videoId', params.videoId)
            scope.setExtra('data', options.body)
            scope.setLevel('error')
            Sentry.captureException(error)
          })
          apm.captureError(error)
          postAnswersSpan.end()
        }
      })
  }

  postAnswers = (embedToken, data) => {
    const { hasEndOfLessonQuiz, isWorkedOut } = this.state
    if (!data.length && !isWorkedOut && hasEndOfLessonQuiz) {
      this.setState({
        warning: 'Something went wrong, please try again!',
      })
      return
    }

    this.fetchRetry(
      `${process.env.API_URL}/answers`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${embedToken}`,
          'Content-Type': 'application/json',
          'elastic-apm-traceparent': apm.getCurrentTransaction(),
          'x-has-end-of-lesson-quiz': hasEndOfLessonQuiz && !isWorkedOut,
        },
        body: JSON.stringify(data),
      },
      8,
      embedToken,
    )
  }

  onVideoEnded = (answerSaved) => {
    const {
      embedToken,
      timeLine,
      startTimeLine,
      endTimeLine,
      finished,
      isWorkedOut,
      hasEndOfLessonQuiz,
      isFullscreen,
    } = this.state

    if (!finished && !answerSaved && !isWorkedOut && hasEndOfLessonQuiz) {
      this.videoEl.currentTime = this.videoEl.currentTime - 5
      this.videoEl.play()
      return
    }

    if (!finished && (isWorkedOut || !hasEndOfLessonQuiz)) {
      this.postAnswers(embedToken, [])
    }

    notifyParent({ type: 'FULLSCREEN', isFullscreen: false })

    const newTimeLine = [...timeLine, { startTimeLine, endTimeLine }]

    this.setState({
      disableControls: true,
      preview: {},
      finished: true,
      endMessage:
        'Thank you for completing this video. See you in the next lesson.',
      bottomText: 'Scroll below to view your diagnostic report for this video.',
    })
    if (!finished) {
      this.socket.emit('INTERACTION', {
        action: 'FINISHED',
        hasEndOfLessonQuiz,
        time: this.videoEl.duration,
        timeLine: newTimeLine,
        embedToken,
        apmTraceparent: apm.getCurrentTransaction(),
      })
      // to notify parent only once in front end
      this.setState({
        timeLine: [...timeLine, { startTimeLine, endTimeLine }],
      })
      notifyParent({ type: 'VIDEO_FINISHED' })

      if (isFullscreen) this.player.toggleFullscreen()
    }
  }

  pageChangeHandler = (annotationId, itemId) => (number) => {
    const preview = cloneDeep(this.state.preview)
    const answers = cloneDeep(this.state.answers)
    const annotationAnswers = answers[annotationId] || {}

    const annotation = preview[annotationId]
    const submitTimeDiff = Date.now() - annotationAnswers[itemId].time

    this.setState({
      preview: {
        ...preview,
        [annotationId]: { ...annotation, number },
      },
      answers: {
        ...answers,
        [annotationId]: {
          ...annotationAnswers,
          [itemId]: {
            ...annotationAnswers[itemId],
            time: submitTimeDiff,
            answerChosen: true,
          },
        },
      },
    })
  }

  annotationPreviewDidMount = (annotationId, itemId, itemType) => () => {
    const { finished } = this.state

    if (!finished) {
      setTimeout(() => {
        const answers = cloneDeep(this.state.answers)
        const annotationAnswers = answers[annotationId] || {}
        const itemAnswer = annotationAnswers[itemId] || {}

        const { preview, answeredQuestions, unAnsweredQuestions } = this.state
        const annotation = preview[annotationId]
        const questions = annotation.items

        this.setState({
          answers: {
            ...answers,
            [annotationId]: {
              ...annotationAnswers,
              [itemId]: {
                ...itemAnswer,
                time: itemAnswer.time
                  ? Date.now() - itemAnswer.time
                  : Date.now(),
                answerChosen: false,
              },
            },
          },
        })

        const prevAnsweredQuestions = answeredQuestions.filter(
          (f) => f.annotationId === annotationId,
        )

        if (itemType !== 'BUTTON') {
          if (
            !finished &&
            !unAnsweredQuestions.length &&
            questions.length !== prevAnsweredQuestions.length
          ) {
            questions.forEach((question, i) => {
              const { _id: questionId } = question
              const index = i + 1

              unAnsweredQuestions.push({
                tag: 'unAns',
                questionId,
                page: index,
                annotationId,
              })
            })
          }
        }
      }, 0)
    }
  }

  annotationPreviewWillUnMount = (annotationId, itemId) => () => {
    const { finished } = this.state
    const answers = cloneDeep(this.state.answers)
    const annotationAnswers = { ...(answers[annotationId] || {}) }

    const { answerChosen } = annotationAnswers[itemId]

    if (!finished && !answerChosen) {
      this.setState({
        answers: {
          ...answers,
          [annotationId]: {
            ...annotationAnswers,
            [itemId]: {
              ...annotationAnswers[itemId],
              time: Date.now() - annotationAnswers[itemId].time,
            },
          },
        },
      })
    }
  }

  answerHandler = (itemId, annotationId, currentPage) => (values) => {
    const answers = cloneDeep(this.state.answers)
    const annotationAnswers = { ...(answers[annotationId] || {}) }
    if (!values.length) {
      this.setState({
        answers: {
          ...answers,
          [annotationId]: {
            ...annotationAnswers,
            [itemId]: {
              ...annotationAnswers[itemId],
              values: false,
            },
          },
        },
      })
    } else {
      this.setState({
        answers: {
          ...answers,
          [annotationId]: {
            ...annotationAnswers,
            [itemId]: {
              ...annotationAnswers[itemId],
              values,
              duration: (Date.now() - annotationAnswers[itemId].time) / 1000,
            },
          },
        },
      })
    }

    this.questionNavigationHandler(annotationId, itemId, currentPage)
  }

  questionNavigationHandler = (annotationId, itemId, currentPage) => {
    const { unAnsweredQuestions, answeredQuestions } = this.state
    const answers = this.state.answers[annotationId] || {}

    const answeredExists = answeredQuestions.findIndex(
      (x) => x.index === currentPage,
    )

    const unAnswered = unAnsweredQuestions.find((ua) => ua.page === currentPage)
    const answered = answeredQuestions.find((ans) => ans.page === currentPage)

    if (unAnswered) {
      const newUnAnswered = { ...unAnswered, tag: 'ans' }
      answeredQuestions.push(newUnAnswered)
      const remainingUA = unAnsweredQuestions.filter(
        (ua) => ua.page !== currentPage,
      )
      this.setState({
        unAnsweredQuestions: remainingUA,
      })
    }

    if (!(answeredExists > -1) && !answers[itemId].values) {
      const newAnswered = { ...answered, tag: 'unAns' }
      unAnsweredQuestions.push(newAnswered)
      const remainingAns = answeredQuestions.filter(
        (ans) => ans.page !== currentPage,
      )
      this.setState({
        answeredQuestions: remainingAns,
      })
    }
  }

  toggleQuestionNavigation = () => () => {
    this.setState((prevState) => ({
      showQuestionNav: !prevState.showQuestionNav,
    }))
  }

  performAction = ({ type, time, value }, annotationId) => {
    const {
      embedToken,
      finished,
      startTimeLine,
      endTimeLine,
      timeLine,
    } = this.state
    const preview = cloneDeep(this.state.preview)
    if (this.videoEl && !this.videoEl.paused) this.videoEl.pause()

    if (preview[annotationId]) delete preview[annotationId]
    let newTimeLine

    switch (type) {
      case 'SEEK':
        if (!finished && startTimeLine > time) {
          // for when question seeks you back
          const filterTimeLine = timeLine.filter(
            (t) => t.startTimeLine < time && t.endTimeLine < time,
          )
          newTimeLine = timeLine.slice(0, filterTimeLine.length)
          this.setState({
            timeLine: newTimeLine,
            startTimeLine: time,
            endTimeLine: time,
          })
        }
        if (!finished && time > endTimeLine) {
          // for normal forward seek
          newTimeLine = [...timeLine, { startTimeLine, endTimeLine }]
          this.setState({
            timeLine: newTimeLine,
            startTimeLine: time,
          })
        }
        this.setState({
          hasPrevTimeLine: false,
        })
        this.videoEl.currentTime = time
        this.videoEl.play()
        break
      case 'URL':
        notifyParent({ type: 'REDIRECT_URL', value })
        this.setState({
          preview: { ...preview },
          disableControls: this.controlsShouldBeDisabled(preview),
        })
        this.videoEl.pause()
        break
      case 'VIDEO':
        this.socket.emit('REDIRECT_START', {
          embedToken,
          videoId: value,
          time,
          apmTraceparent: apm.getCurrentTransaction(),
        })
        break
      default:
      // do nothing
    }

    if (!finished) {
      this.socket.emit('INTERACTION', {
        action: 'TIME',
        time: this.videoEl.currentTime,
        timeLine: newTimeLine,
        embedToken,
        apmTraceparent: apm.getCurrentTransaction(),
      })
    }
  }

  controlsShouldBeDisabled = (preview) => {
    const annotationIds = Object.keys(preview)

    return annotationIds.filter((id) => preview[id].pause).length > 0
  }

  buttonActionHandler = ({ _id: itemId, options }, annotationId) => (
    action,
  ) => {
    if (!action || !action.type) return

    const preview = cloneDeep(this.state.preview)
    const { embedToken, timeLine, finished } = this.state
    const answers = cloneDeep(this.state.answers)
    const annotationAnswers = { ...(answers[annotationId] || {}) }

    if (!finished) {
      this.socket.emit('INTERACTION', {
        action: 'CLICK',
        click: itemId,
        timeLine,
        embedToken,
        apmTraceparent: apm.getCurrentTransaction(),
      })
    }

    const data = [
      {
        item: itemId,
        // eslint-disable-next-line no-underscore-dangle
        option: options[0]._id,
        duration: 0,
        leftApp: false,
        annotation: annotationId,
      },
    ]

    if (!finished) {
      this.postAnswers(embedToken, data)
    }

    delete preview[annotationId]

    this.performAction(action, annotationId)

    this.setState({
      answers: {
        ...answers,
        [annotationId]: {
          ...annotationAnswers,
          [itemId]: {
            ...annotationAnswers[itemId],
            values: [{ value: options[0]._id, duration: 0 }],
            duration: (Date.now() - annotationAnswers[itemId].time) / 1000,
          },
        },
      },
      preview: { ...preview },
      disableControls: this.controlsShouldBeDisabled(preview),
      answerData: data,
    })
  }

  answerSubmitHandler = (items, annotationId) => () => {
    const answers = cloneDeep(this.state.answers)
    const preview = cloneDeep(this.state.preview)
    const annotationAnswers = cloneDeep(answers[annotationId])
    const { finished } = this.state
    const answerItemIds = Object.keys(annotationAnswers)
    const answerCount = answerItemIds.filter(
      (itemId) => annotationAnswers[itemId].values,
    ).length

    if (items.length === answerCount) {
      let annotationAction
      const data = []
      const { embedToken } = this.state

      this.setState({ ignoreAnnotationId: annotationId, warning: '' })

      answerItemIds.forEach((itemId) => {
        const { values, duration } = annotationAnswers[itemId]

        const submitTimeDiff = Date.now() - annotationAnswers[itemId].time

        this.setState({
          answers: {
            ...answers,
            [annotationId]: {
              ...annotationAnswers,
              [itemId]: {
                ...annotationAnswers[itemId],
                time: submitTimeDiff,
                answerChosen: true,
              },
            },
          },
        })

        values.forEach(({ value, leftApp, action }) => {
          if (!annotationAction && action) annotationAction = action
          data.push({
            item: itemId,
            option: value,
            duration,
            leftApp,
            annotation: annotationId,
          })
        })
      })

      if (!finished) {
        this.setState({
          answerData: data,
        })
        setTimeout(() => {
          this.postAnswers(embedToken, data)
        }, 0)
      }

      const timeLeft = this.videoEl.duration - this.videoEl.currentTime

      const cleanUpAnnotationPreview = () => {
        if (!this.state.warning) {
          if (preview[annotationId]) delete preview[annotationId]

          this.setState({ preview })
        }
      }

      setTimeout(() => {
        if (timeLeft < 5) {
          // if this is the end of the lession quiz
          cleanUpAnnotationPreview(annotationId)
          if (finished) this.onVideoEnded()
        } else if (!annotationAction) {
          cleanUpAnnotationPreview(annotationId)

          this.videoEl.play()
        } else {
          this.performAction(annotationAction, annotationId)
          cleanUpAnnotationPreview(annotationId)
        }
      }, 0)
    } else {
      this.setState({
        warning: 'You have not answered all the questions yet.',
      })
    }
  }

  setVideoQuality = (level) => {
    if (level < this.hls.levels.length) {
      this.hls.currentLevel = level
      this.videoEl.play()
    }
  }

  rewindHandler = () => {
    const { playbackRate } = this.state
    const annotations = cloneDeep(this.state.annotations)
    const { currentTime } = this.videoEl
    const seekTime = currentTime - 5
    const previewCount = annotations.filter(({ pause, startTime, endTime }) => {
      const padding = (timeUpdateDiff * playbackRate) / 2
      const et = endTime || startTime + padding
      const st = startTime - padding

      return (
        pause &&
        ((et >= seekTime && et <= currentTime) ||
          (st >= seekTime && st <= currentTime))
      )
    }).length

    const seekableTime = Math.floor(currentTime) <= this.state.startTimeLine

    if (!previewCount && !seekableTime) {
      this.videoEl.currentTime = seekTime
    }

    this.videoEl.currentTime = seekTime
  }

  handleDeleteWarning = () => {
    this.setState({ warning: '' })
  }

  render() {
    const {
      isFullscreen,
      error,
      time,
      streamingUrl,
      thumbnail,
      canSeek,
      finished,
      preview,
      disableControls,
      warning,
      duration,
      videoQualities,
      endMessage,
      bottomText,
      hasEndOfLessonQuiz,
    } = this.state

    // this will check the button type in advance for the css in mobile view
    const buttonTypes = Object.keys(preview).map((annotationId) => {
      const { items, number } = preview[annotationId]
      const item = items[number - 1]

      if (item.type === 'BUTTON') {
        return true
      }
      return false
    })

    // set button type and with ! prioritize annotation than button
    const buttonType = !buttonTypes.includes(false)

    return streamingUrl ? (
      <Player
        ref={this.onPlayerLoad}
        playsInline
        className="videoReact"
        startTime={duration && time >= duration ? duration - 0.2 : time}
        preload="auto"
        poster={thumbnail}
        fluid={false}
        width={window.innerWidth}
        height={window.innerHeight}
      >
        <Shortcut
          disabled={disableControls || isMobile || endMessage}
          shortcuts={[37, 74, 39, 76, 36, 35].map((keyCode) => ({
            // [Left arrow, j, Right arrow, l, Home, End]
            keyCode,
            handle: () => {},
          }))}
        />
        <BigPlayButton position="center" />
        <ControlBar disableCompletely={endMessage}>
          <PlayToggle order={1} />

          <ControlButton
            onClick={this.rewindHandler}
            order={1.2}
            title="Rewind 5s"
          >
            <svg
              viewBox="0 0 56 56"
              style={{ height: 16, width: 16, fill: 'white' }}
            >
              <path d="M56.461 8.625a1.001 1.001 0 00-1.036.069L29 27.277V9.5a1.001 1.001 0 00-1.575-.818l-27 19a.999.999 0 000 1.635l27 19a.992.992 0 001.036.071c.331-.172.539-.515.539-.888V29.723l26.425 18.583a1.005 1.005 0 001.036.07c.331-.173.539-.516.539-.889V9.513c0-.373-.208-.716-.539-.888z" />
            </svg>
          </ControlButton>

          <CurrentTimeDisplay order={1.3} />
          <TimeDivider order={1.4} />
          <DurationDisplay order={1.5} />
          <VolumeMenuButton order={1.6} alwaysShowVolume />
          <ProgressControl disabled={!canSeek} order={1.7} />
          <VideoPlaybackRateMenuButton
            className="playbackRate"
            rates={playbackRates}
            order={1.8}
            setPlaybackRate={(rate) => {
              if (rate) this.videoEl.playbackRate = rate
            }}
            videoEl={this.videoEl}
          />
          {this.hls && (
            <VideoQualityMenuButton
              qualities={videoQualities}
              hls={this.hls}
              setVideoQuality={this.setVideoQuality}
              order={1.9}
            />
          )}
          <ControlButton
            onClick={() => {
              this.player.toggleFullscreen()
              notifyParent({
                type: 'FULLSCREEN',
                isFullscreen: !isFullscreen,
              })
              setTimeout(() => {
                this.videoEl.play()
                resizePlayer()
              }, 100)
            }}
            title={isFullscreen ? 'Exit Full Screen' : 'Full Screen'}
            order={1.91}
          >
            <svg
              viewBox="0 0 24 24"
              style={{ height: 16, width: 16, fill: 'white' }}
            >
              <path
                d={
                  isFullscreen
                    ? 'M14,14H19V16H16V19H14V14M5,14H10V19H8V16H5V14M8,5H10V10H5V8H8V5M19,8V10H14V5H16V8H19Z'
                    : 'M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z'
                }
              />
            </svg>
          </ControlButton>
          <FullscreenToggle disabled />
        </ControlBar>
        {endMessage ? (
          <AnnotationPreviewWrapper buttonType="endMessage">
            <EndMessage
              endMessage={endMessage}
              bottomText={bottomText}
              hasEndOfLessonQuiz={hasEndOfLessonQuiz}
            />
          </AnnotationPreviewWrapper>
        ) : (
          <AnnotationPreviewWrapper
            key={Object.keys(preview).length}
            buttonType={buttonType && !!Object.keys(preview).length}
          >
            {Object.keys(preview).map((annotationId) => {
              const { items, position, dimension, number } = preview[
                annotationId
              ]
              const item = items[number - 1]
              const { answers, showQuestionNav, title, viewer } = this.state
              let { answeredQuestions, unAnsweredQuestions } = this.state
              answeredQuestions = answeredQuestions.filter(
                (ansQn) => ansQn.annotationId === annotationId,
              )
              unAnsweredQuestions = unAnsweredQuestions.filter(
                (uAnsQn) => uAnsQn.annotationId === annotationId,
              )
              const { _id: itemId } = item
              const paginationShouldRender =
                items.length > 1 && item.type !== 'BUTTON'
              return (
                <div
                  key={annotationId}
                  style={
                    item.type === 'BUTTON'
                      ? {
                          fontSize: '2.1vw',
                          position: 'absolute',
                          zIndex: 2,
                          ...position,
                          ...dimension,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }
                      : { height: '100%' }
                  }
                >
                  <>
                    <AnnotationPreview
                      key={itemId}
                      item={item}
                      onComponentDidMount={this.annotationPreviewDidMount(
                        annotationId,
                        itemId,
                        item.type,
                      )}
                      onComponentWillUnMount={this.annotationPreviewWillUnMount(
                        annotationId,
                        itemId,
                      )}
                      finished={finished}
                      answers={
                        ((answers[annotationId] || {})[itemId] || {}).values
                      }
                      header={
                        <Header
                          title={title}
                          current={number}
                          total={items.length}
                          toggleQuestionNavigation={this.toggleQuestionNavigation()}
                          answeredQuestions={answeredQuestions.length}
                        />
                      }
                      showQuestionNav={showQuestionNav}
                      questionNavigation={
                        paginationShouldRender && (
                          <QuestionNavigation
                            showQuestionNav={showQuestionNav}
                            answeredQuestions={answeredQuestions}
                            unAnsweredQuestions={unAnsweredQuestions}
                            total={items.length}
                            current={number}
                            onChange={this.pageChangeHandler(
                              annotationId,
                              itemId,
                            )}
                            toggleQuestionNavigation={this.toggleQuestionNavigation()}
                          />
                        )
                      }
                      footerButtons={
                        <FooterButtons
                          current={number}
                          total={items.length}
                          onChange={this.pageChangeHandler(
                            annotationId,
                            itemId,
                          )}
                          onSubmitClick={this.answerSubmitHandler(
                            items,
                            annotationId,
                          )}
                        />
                      }
                      onChange={this.answerHandler(
                        itemId,
                        annotationId,
                        number,
                      )}
                      onButtonAction={this.buttonActionHandler(
                        item,
                        annotationId,
                      )}
                    />
                    {warning && (
                      <Toast
                        toastItem={{
                          description: warning,
                          backgroundColor: '#FAA030',
                        }}
                        position="top-right"
                        dismissTime="5000"
                        dismissToastItem={this.handleDeleteWarning}
                      />
                    )}
                  </>
                </div>
              )
            })}
          </AnnotationPreviewWrapper>
        )}
      </Player>
    ) : (
      error && (
        <div className="message">
          <h5>{error}</h5>
        </div>
      )
    )
  }
}

EmbedPage.propTypes = {}

export default EmbedPage
