import { init as initApm } from '@elastic/apm-rum'
// eslint-disable-next-line no-unused-vars
export default initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'ivy-embed',

  serverUrl: 'https://insights.innovatetech.io/apm',

  // Set service version (required for sourcemap feature)
  serviceVersion: '1.0.1',

  distributedTracingOrigins: [
    new URL(process.env.API_URL).origin,
    process.env.WS_URL,
  ],
  environment: process.env.DEPLOYMENT_ENV,
  pageLoadTransactionName: 'ivy-embed',
})
