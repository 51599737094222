const notifyParent = (data) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data))
  } else if (window.parent) {
    window.parent.postMessage(
      JSON.stringify(data),
      document.referrer || 'file://',
    )
  }
}

export default notifyParent
