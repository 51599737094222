import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ clicked, children, styleClass }) => (
  <div
    role="presentation"
    type="button"
    className={`button button--gradient-primary button--rounded button--xlg my-6 ${styleClass}`}
    onClick={clicked}
  >
    {children}
  </div>
)

Button.propTypes = {
  clicked: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  styleClass: PropTypes.string,
}

export default Button
