import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const ControlButton = ({ className, onClick, children, title }) => (
  <div
    role="presentation"
    onClick={onClick}
    className={`svgIcon video-react-control video-react-button ${className}`}
    title={title}
  >
    {children}
  </div>
)

ControlButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
}

export default ControlButton
