/* eslint-disable import/first */
import './utils/apm'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import './assets/grid.css'
import './assets/style.css'

// Import root app
import App from 'containers/App'

Sentry.init({
  dsn: process.env.SENTRY_DSN_REACT,
  environment: process.env.NODE_ENV,
  debug: process.env.NODE_ENV === 'development',
  sendDefaultPii: true,
  release: process.env.REACT_APP_SENTRY_RELEASE,
})

ReactDOM.render(<App />, document.getElementById('app'))
