import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import MenuButton from 'components/ControlMenu/MenuButton'

const VideoQualityMenuButton = ({
  qualities,
  hls,
  setVideoQuality,
  className,
}) => {
  const items = qualities.map((quality) => ({
    label: `${quality}p`,
    value: quality,
  }))

  return (
    <MenuButton
      className={classNames('video-react-playback-rate', className)}
      onSelectItem={(index) => {
        if (index < qualities.length) {
          setVideoQuality(index)
        }
      }}
      items={items}
      selectedIndex={hls.currentLevel >= 0 ? hls.currentLevel : 0}
      title="Video Quality"
    >
      <span className="video-react-control-text">Playback Rate</span>
      <div className="video-react-playback-rate-value">
        {hls.currentLevel >= 0 ? `${qualities[hls.currentLevel]}p` : '...'}
      </div>
    </MenuButton>
  )
}

VideoQualityMenuButton.propTypes = {
  hls: PropTypes.object,
  qualities: PropTypes.array,
  className: PropTypes.string,
  setVideoQuality: PropTypes.func,
}

export default VideoQualityMenuButton
