import React from 'react'
import PropTypes from 'prop-types'
import dummyImage from 'assets/16x9.png'

const AnnotationPreviewWrapper = ({ buttonType, styles, children }) => (
  <div
    style={{
      height: '100%',
      width: '100%',
      top: '0',
      ...styles,
      position: 'absolute',
      left: 0,
    }}
  >
    {buttonType ? (
      <div className="imageWrapper">
        <img
          src={dummyImage}
          alt="test"
          style={{
            display: 'block',
            opacity: 0,
            pointerEvents: 'none',
          }}
          className="imageStyle"
        />
        {children}
      </div>
    ) : (
      children
    )}
  </div>
)

AnnotationPreviewWrapper.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
  buttonType: PropTypes.bool,
}

export default AnnotationPreviewWrapper
