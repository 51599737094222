import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'

const FooterButtons = ({ total, current, onChange, onSubmitClick }) => {
  return (
    <React.Fragment>
      {current !== 1 ? (
        <div
          className="button button--outline-primary button--rounded button--lg my-6 previous-question"
          role="presentation"
          onClick={() => onChange(current - 1)}
        >
          Previous Question
        </div>
      ) : null}

      {current !== total ? (
        <div
          className="button button--gradient-primary button--rounded button--xlg my-6 next-question"
          role="presentation"
          onClick={() => onChange(current + 1)}
        >
          Next Question
        </div>
      ) : (
        <Button
          type="submit"
          clicked={onSubmitClick}
          styleClass={total === 1 ? 'continue' : 'submit'}
        >
          {total === 1 ? 'Continue' : 'Submit'}
        </Button>
      )}
    </React.Fragment>
  )
}

FooterButtons.propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  onChange: PropTypes.func,
  onSubmitClick: PropTypes.func,
}

export default FooterButtons
