const sanitize = require('sanitize-html')

module.exports = (htmlString) => {
  const colors = [
    /^#(0x)?[0-9a-f]+$/i,
    /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
  ]
  const sizes = [/^auto$/, /^[\d.]+(?:px|rem|em|%|vw|vh)$/]

  return sanitize(htmlString, {
    allowedTags: [
      'del',
      'em',
      'img',
      'ins',
      'p',
      'span',
      'strong',
      'sub',
      'sup',
    ],
    allowedAttributes: {
      '*': ['style'],
      img: ['src'],
    },
    exclusiveFilter: (frame) => frame.tag === 'img' && !frame.text.trim(),
    allowedSchemes: ['data', 'http', 'https', 'blob'],
    allowedStyles: {
      '*': {
        // Match HEX and RGB
        color: colors,
        'background-color': colors,
        'text-align': [/^(left|right|center)$/],
        // Match any number with px, em, or %
        'font-size': sizes,
        'font-weight': [/^(bold|normal)$/],
        height: sizes,
        width: sizes,
        padding: sizes,
        margin: sizes,
        'margin-left': sizes,
        'margin-right': sizes,
        float: [/^(left|none|right)$/],
      },
    },
  })
}
