/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'

import SafeHtml from 'components/SafeHtml'
import ImageModal from 'components/ImageModal'
import getTextContent from 'utils/getTextContent'
import Sanitizer from 'components/Sanitizer'
import getImageSrc from '../../utils/getImageSrc'
import './style.css'

class AnnotationPreview extends React.Component {
  state = {
    imageMagnify: false,
    answerImages: [],
    questionImages: [],
  }

  leftApp = false

  componentDidMount() {
    const { answers, item, finished, onComponentDidMount } = this.props

    const hasAnswers = answers && answers.length

    if (answers && hasAnswers) this.leftApp = answers[0].leftApp

    if (finished) {
      this.setState({ disable: true })
    }

    if (hasAnswers && item.type === 'SCQ') {
      this.setState({ value: answers[0].value })
    } else if (hasAnswers) {
      this.setState({
        ...answers.reduce((acc, { value }) => ({ ...acc, [value]: true }), {}),
      })
    }

    // eslint-disable-next-line no-unused-vars
    const answerImages = (item.options || []).map(({ content }) => {
      const imgSrc = getImageSrc(content)
      if (imgSrc.length) {
        this.state.answerImages.push(imgSrc[0].src)
      }
      return null
    })

    // eslint-disable-next-line no-unused-vars
    let questionImages = getImageSrc(item.content)
    questionImages = [...questionImages]
    if (questionImages.length) {
      questionImages.map((img) => this.state.questionImages.push(img.src))
    }

    if (onComponentDidMount) onComponentDidMount()

    const { eventKey } = this.supportedVisibilityChangeEvent()
    document.addEventListener(eventKey, this.appFocusHandler)
  }

  componentWillUnmount() {
    const { onComponentWillUnMount } = this.props
    const { eventKey } = this.supportedVisibilityChangeEvent()
    document.removeEventListener(eventKey, this.appFocusHandler)
    if (onComponentWillUnMount) onComponentWillUnMount()
  }

  supportedVisibilityChangeEvent = () =>
    [
      { stateKey: 'hidden', eventKey: 'visibilitychange' },
      { stateKey: 'webkitHidden', eventKey: 'webkitvisibilitychange' },
      { stateKey: 'mozHidden', eventKey: 'mozvisibilitychange' },
      { stateKey: 'msHidden', eventKey: 'msvisibilitychange' },
    ].find(({ stateKey: key }) => key in document)

  appFocusHandler = () => {
    const { stateKey } = this.supportedVisibilityChangeEvent()
    const isBlur = document[stateKey]
    if (isBlur) this.leftApp = true
    // `${isBlur ? 'Blur' : 'Focus'} at ${new Date()}`
  }

  changeHandler = (itemId) => (e) => {
    const { onChange, item } = this.props

    if (itemId) {
      this.setState({ [itemId]: e.target.checked })
    } else {
      this.setState({ value: e.target.value })
    }

    if (onChange) {
      setTimeout(() => {
        const { value } = this.state
        if (value) {
          const { action } =
            item.options.filter(({ _id }) => _id === value).pop() || {}

          onChange([{ value, leftApp: this.leftApp, action }])
        } else {
          const excludeArray = [
            'imageMagnify',
            'answerImages',
            'questionImages',
          ]
          const mcqValues = Object.keys(this.state).filter(
            (val) => !excludeArray.includes(val),
          )
          onChange(
            mcqValues
              .filter((key) => this.state[key])
              .map((optionId) => {
                const { action } =
                  item.options.filter(({ _id }) => _id === itemId).pop() || {}

                return {
                  action,
                  leftApp: this.leftApp,
                  value: optionId,
                }
              }),
          )
        }
      }, 0)
    }
  }

  formatQuestion = (content) => {
    let image = getImageSrc(content)
    const text = getTextContent(content)
    image = [...image]
    if (!image.length) {
      return (
        <div className="question">
          <Sanitizer html={text} />
        </div>
      )
    }
    if (image.length) {
      return (
        <>
          <div className="question">
            <Sanitizer html={text} />
          </div>
          <div className="img-wrapper">
            <div className="row">
              {image.map((img) => (
                <div style={{ position: 'relative', marginBottom: '1.5rem' }}>
                  <img
                    src={img.src}
                    alt="question"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                  <span
                    className="icon-zoom cursor-pointer mr-0 mx-0"
                    role="presentation"
                    onClick={() => {
                      this.setState((prevState) => ({
                        questionClicked: true,
                        answerClicked: false,
                        imageMagnify: !prevState.imageMagnify,
                        currentImage: img.src,
                      }))
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )
    }
  }

  closeImageMagnify = () => {
    this.setState((prevState) => ({
      imageMagnify: !prevState.imageMagnify,
      questionClicked: false,
      answerClicked: false,
    }))
  }

  render() {
    const {
      classes,
      header,
      item,
      questionNavigation,
      footerButtons,
      onButtonAction,
    } = this.props
    const {
      value,
      disable,
      answerClicked,
      answerImages,
      questionImages,
      currentImage,
      imageMagnify,
    } = this.state

    const imageModel = (
      <ImageModal
        images={answerClicked ? answerImages : questionImages}
        currentImage={currentImage}
        handleClose={() => this.closeImageMagnify()}
      />
    )

    const renderQuestion = () => (
      <div className="modal-dialog modal-dialog-scrollable modal-dialog--xl">
        <div
          className={
            imageMagnify ? 'modal-content modal-open' : 'modal-content'
          }
        >
          {header}
          <div className="modal-body modal-body--bg-alt text-dark">
            {questionNavigation}
            <div className="formControl">
              <div className="row">
                <div className="col-md-6">
                  {!!getTextContent(item.content) &&
                    this.formatQuestion(item.content)}
                </div>
                <div className="col-md-6">
                  <div className="padding-x-15">
                    <h4 className="heading">Choose an answer</h4>
                    {item.type === 'SCQ' && (
                      <form className="mb-3">
                        <div className="row">
                          {(item.options || []).map(({ _id, content }, i) => {
                            const text = getTextContent(content)
                            const img = getImageSrc(content)
                            // text = [...text]
                            if (img.length) {
                              return (
                                <div className="col-md-6">
                                  {!!getTextContent(content || '') && (
                                    <div className="text-sm">
                                      <input
                                        key={_id}
                                        disabled={disable}
                                        type="radio"
                                        id={_id}
                                        name="radio-group"
                                        value={_id}
                                        checked={value === _id || false}
                                        onChange={this.changeHandler()}
                                      />
                                      <label
                                        htmlFor={_id}
                                        className={`option-${i + 1}`}
                                      >
                                        <div className="display-flex">
                                          <div className="mr-2">
                                            {String.fromCharCode(97 + i)}){' '}
                                          </div>
                                          <div key={_id}>
                                            <p className="m-0" key={_id}>
                                              <Sanitizer html={text} />
                                            </p>
                                          </div>
                                        </div>

                                        <div className="divider" />
                                        <div
                                          className="img-container"
                                          style={{
                                            backgroundImage: `url(
                                              ${JSON.stringify(img[0].src)}
                                            )`,
                                          }}
                                        >
                                          <span
                                            className="icon-zoom mr-0 mx-0"
                                            role="presentation"
                                            onClick={() => {
                                              this.setState((prevState) => ({
                                                answerClicked: true,
                                                questionClicked: true,
                                                imageMagnify: !prevState.imageMagnify,
                                                currentImage: img[0].src,
                                              }))
                                            }}
                                          />
                                        </div>
                                      </label>
                                    </div>
                                  )}
                                </div>
                              )
                            }
                            return (
                              <div className="col-md-12">
                                {!!getTextContent(content || '') && (
                                  <div className="text-sm">
                                    <input
                                      key={_id}
                                      disabled={disable}
                                      type="radio"
                                      id={_id}
                                      name="radio-group"
                                      value={_id}
                                      checked={value === _id || false}
                                      onChange={this.changeHandler()}
                                    />
                                    <label
                                      htmlFor={_id}
                                      className={`option-${i + 1}`}
                                    >
                                      <div className="display-flex">
                                        <div className="mr-2">
                                          {String.fromCharCode(97 + i)}){' '}
                                        </div>
                                        {<Sanitizer html={text} />}
                                      </div>
                                    </label>
                                  </div>
                                )}
                              </div>
                            )
                          })}
                        </div>
                      </form>
                    )}
                    <div className="modal-footer mt-auto">{footerButtons}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {imageMagnify ? imageModel : false}
        </div>
      </div>
    )

    const renderButton = () => {
      const { content, action = {} } = item.options[0]

      const { finished } = this.props
      let buttonActions = {
        onClick: () => onButtonAction(action),
      }
      let className = 'button button--floating button--rounded'

      if (finished) {
        const { answers } = this.props
        const hasAnswers = answers
        if (!hasAnswers && action.type === 'SEEK') {
          buttonActions = {}
          className = 'button button--floating button--rounded disabled'
        }
      }

      return (
        <div
          type="button"
          className={className}
          {...action.type && buttonActions}
        >
          <Sanitizer html={content || ''} />
        </div>
      )
    }

    return item.type === 'BUTTON' ? renderButton() : renderQuestion()
  }
}

AnnotationPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  footerButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  questionNavigation: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  finished: PropTypes.bool,
  onChange: PropTypes.func,
  onButtonAction: PropTypes.func,
  onComponentDidMount: PropTypes.func,
  onComponentWillUnMount: PropTypes.func,
  answers: PropTypes.array,
}

export default AnnotationPreview
