import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import MenuItem from 'components/ControlMenu'

class MenuButton extends Component {
  state = {
    active: false,
    activateIndex: 0,
  }

  componentDidMount() {
    const { selectedIndex } = this.props
    this.setState({ activateIndex: selectedIndex })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedIndex !== prevState.selectedIndex) {
      return { activateIndex: nextProps.selectedIndex }
    }

    return null
  }

  commitSelection = (index) => {
    this.setState({
      activateIndex: index,
      active: false,
    })
    this.handleIndexChange(index)
  }

  activateMenuItem = (index) => {
    this.setState({
      activateIndex: index,
    })
    this.handleIndexChange(index)
  }

  handleIndexChange = (index) => {
    const { onSelectItem } = this.props
    onSelectItem(index)
  }

  handleClick = () => {
    this.setState({
      active: !this.state.active,
    })
  }

  handleFocus = () => {
    document.addEventListener('keydown', this.handleKeyPress)
  }

  handleBlur = () => {
    this.setState({
      active: false,
    })
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  handleUpArrow = (e) => {
    const { items } = this.props
    if (this.state.active) {
      e.preventDefault()
      let newIndex = this.state.activateIndex - 1
      if (newIndex < 0) {
        newIndex = items.length ? items.length - 1 : 0
      }
      this.activateMenuItem(newIndex)
    }
  }

  handleDownArrow = (e) => {
    const { items } = this.props
    if (this.state.active) {
      e.preventDefault()
      let newIndex = this.state.activateIndex + 1
      if (newIndex >= items.length) {
        newIndex = 0
      }
      this.activateMenuItem(newIndex)
    }
  }

  handleTab = (e) => {
    if (this.state.active) {
      e.preventDefault()
      this.commitSelection(this.state.activateIndex)
    }
  }

  handleReturn = (e) => {
    e.preventDefault()
    if (this.state.active) {
      this.commitSelection(this.state.activateIndex)
    } else {
      this.setState({
        active: true,
      })
    }
  }

  handleEscape = () => {
    this.setState({
      active: false,
      activateIndex: 0,
    })
  }

  handleKeyPress = (event) => {
    // Escape (27) key
    if (event.which === 27) {
      this.handleEscape(event)
    } else if (event.which === 9) {
      // Tab (9) key
      this.handleTab(event)
    } else if (event.which === 13) {
      // Enter (13) key
      this.handleReturn(event)
    } else if (event.which === 38) {
      // Up (38) key
      this.handleUpArrow(event)
    } else if (event.which === 40) {
      // Down (40) key press
      this.handleDownArrow(event)
    }
  }

  handleSelectItem = (i) => {
    this.commitSelection(i)
  }

  renderMenu = () => {
    if (!this.state.active) {
      return null
    }

    const { items } = this.props
    return (
      <div
        className="video-react-menu video-react-lock-showing"
        role="presentation"
        style={{ maxHeight: '18em' }}
        onClick={(e) => e.preventDefault()}
      >
        <ul className="video-react-menu-content">
          {items.map((item, i) => (
            <MenuItem
              key={item.value}
              item={item}
              index={i}
              onSelectItem={this.handleSelectItem}
              activateIndex={this.state.activateIndex}
            />
          ))}
        </ul>
      </div>
    )
  }

  render() {
    const { inline, className, title } = this.props

    return (
      <button
        type="button"
        className={classNames(
          className,
          {
            'video-react-menu-button-inline': !!inline,
            'video-react-menu-button-popup': !inline,
            'video-react-menu-button-active': this.state.active,
          },
          'video-react-control video-react-button video-react-menu-button',
        )}
        tabIndex="0"
        ref={(c) => {
          this.menuButton = c
        }}
        onClick={this.handleClick}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        title={title || ''}
      >
        {this.props.children}
        {this.renderMenu()}
      </button>
    )
  }
}

MenuButton.propTypes = {
  inline: PropTypes.bool,
  items: PropTypes.array,
  className: PropTypes.string,
  onSelectItem: PropTypes.func,
  children: PropTypes.any,
  title: PropTypes.string,
  selectedIndex: PropTypes.number,
}

export default MenuButton
