import PropTypes from 'prop-types'
import React from 'react'
import Scroll from '../../assets/scroll.svg'
import './style.css'

const EndMessage = ({ endMessage, bottomText, hasEndOfLessonQuiz }) => (
  <div className="endMessage">
    <h1>{endMessage}</h1>
    {hasEndOfLessonQuiz && (
      <div className="bottomText">
        <img src={Scroll} alt="aa" height={32} width={32} />
        <h2 role="presentation">{bottomText}</h2>
      </div>
    )}
  </div>
)

EndMessage.propTypes = {
  endMessage: PropTypes.string,
  bottomText: PropTypes.string,
  hasEndOfLessonQuiz: PropTypes.bool,
}

export default EndMessage
