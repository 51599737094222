const getTextContent = (htmlString) => {
  let tempString = htmlString
  tempString = tempString.replace('<p></p>\n', '')
  tempString = tempString.replace(/<p>/g, '')
  tempString = tempString.replace(/<\/p>/g, '<br/>')
  tempString = tempString.replace(/<img[^>]*>/g, '')

  return tempString
}

export default getTextContent
