import React from 'react'
import PropTypes from 'prop-types'
import PinchZoomPan from '../PinchZoomPan'

class ImageModal extends React.Component {
  state = { currentImageIndex: false }

  componentDidMount() {
    const { images, currentImage } = this.props
    const currentImageIndex = images.indexOf(currentImage)
    this.setState({
      currentImageIndex,
      currentImageSrc: currentImage,
    })

    document.onkeydown = (e) => {
      switch (e.keyCode) {
        case 37:
          this.leftImage()
          break
        case 39:
          this.rightImage()
          break
        default:
          break
      }
    }
  }

  leftImage = () => {
    const { images } = this.props
    const { currentImageIndex } = this.state

    if (currentImageIndex - 1 < 0) return null
    return this.setState({
      currentImageSrc: images[currentImageIndex - 1],
      currentImageIndex: currentImageIndex - 1,
    })
  }

  rightImage = () => {
    const { images } = this.props
    const { currentImageIndex } = this.state

    if (currentImageIndex + 1 > images.length - 1) return null
    return this.setState({
      currentImageSrc: images[currentImageIndex + 1],
      currentImageIndex: currentImageIndex + 1,
    })
  }

  render() {
    const { handleClose, images } = this.props
    const { currentImageIndex, currentImageSrc } = this.state
    return (
      <React.Fragment>
        {
          <>
            <div className="maximize-image__wrapper">
              <div className="maximize-image__topbar">
                <div className="m-auto">{`${currentImageIndex + 1}/${
                  images.length
                }`}</div>
                <div className="m-4">
                  <span
                    role="presentation"
                    className="icon-cross cursor-pointer"
                    onClick={handleClose}
                  />
                </div>
              </div>

              <div className="maximize-image__body">
                {currentImageIndex + 1 !== 1 ? (
                  <span
                    role="presentation"
                    className="icon-arrow-left cursor-pointer"
                    onClick={this.leftImage}
                  />
                ) : (
                  <span />
                )}
                <div style={{ flex: '0 0 65%', maxWidth: '65%' }}>
                  <PinchZoomPan width="400" height="400">
                    {(x, y, scale) => (
                      <img
                        src={currentImageSrc}
                        alt="currentImg"
                        style={{
                          pointerEvents: scale === 1 ? 'auto' : 'none',
                          transform: `translate3d(${x}px, ${y}px, 0) scale(${scale})`,
                          transformOrigin: '0 0',
                        }}
                      />
                    )}
                  </PinchZoomPan>
                </div>
                {currentImageIndex + 1 !== images.length ? (
                  <span
                    role="presentation"
                    className="icon-arrow-right cursor-pointer"
                    onClick={this.rightImage}
                  />
                ) : (
                  <span />
                )}
              </div>

              <div className="maximize-image__nav">
                <div className="row display-flex justify-content-center">
                  {images.map((image) => (
                    <div
                      className={
                        currentImageIndex === images.indexOf(image)
                          ? 'col-auto nav-img__container current-img'
                          : 'col-auto nav-img__container'
                      }
                    >
                      <img
                        src={image}
                        alt="currentImg"
                        width={50}
                        role="presentation"
                        onClick={() =>
                          this.setState({
                            currentImageSrc: image,
                            currentImageIndex: images.indexOf(image),
                          })
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        }
      </React.Fragment>
    )
  }
}

ImageModal.propTypes = {
  handleClose: PropTypes.func,
  currentImage: PropTypes.number,
  images: PropTypes.array,
}

export default ImageModal
