import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

class Toast extends React.Component {
  deleteToast = () => {
    this.props.dismissToastItem()
  }

  render() {
    const { toastItem, position } = this.props
    return (
      <>
        <div className={`notification-container ${position}`}>
          <div
            key={toastItem.title}
            className={`notification toast ${position}`}
            style={{ backgroundColor: toastItem.backgroundColor }}
          >
            <button
              type="button"
              onClick={() => this.deleteToast(toastItem.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                fill="white"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              </svg>
            </button>
            <div className="notification-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                fill="white"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
              </svg>
            </div>
            <div>
              <p className="notification-message">{toastItem.description}</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

Toast.propTypes = {
  toastItem: PropTypes.object.isRequired,
  position: PropTypes.string,
  dismissToastItem: PropTypes.func,
}

export default Toast
