/* eslint-disable no-undef */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import loadScript from 'load-script'
import DOMPurify from 'dompurify'

const SCRIPT =
  'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.6/MathJax.js?config=TeX-MML-AM_HTMLorMML'

export default class extends Component {
  static propTypes = {
    config: PropTypes.object,
    className: PropTypes.string,
    html: PropTypes.string,
    style: PropTypes.string,
  }

  static defaultProps = {
    config: {},
    html: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      oldMath: props.html,
    }
  }

  onLoad = (err) => {
    this.setState({
      loaded: true,
    })
    if (err) console.log(err)
    else {
      MathJax.Hub.Config(
        Object.assign(
          {
            showMathMenu: true,
            tex2jax: { inlineMath: [['$', '$'], ['\\(', '\\)']] },
            skipStartupTypeset: true,
          },
          this.props.config,
        ),
      )
      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, this.preview])
    }
  }

  componentDidMount() {
    this.preview.innerHTML = DOMPurify.sanitize(this.props.html)
    // eslint-disable-next-line no-unused-expressions
    this.state.loaded
      ? MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, this.preview])
      : loadScript(SCRIPT, this.onLoad)
  }

  shouldComponentUpdate(nextProps) {
    if (!nextProps.html) return false
    return nextProps.html !== this.state.oldMath
  }

  componentDidUpdate() {
    this.preview.innerHTML = this.props.html
    // eslint-disable-next-line no-undef
    MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, this.preview])
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ oldMath: nextProps.html })
  }

  render() {
    return (
      <div
        className={this.props.className}
        id="react-mathjax-preview"
        style={this.props.style}
      >
        <div
          id="react-mathjax-preview-result"
          ref={(node) => {
            this.preview = node
          }}
        />
      </div>
    )
  }
}
