import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'

import './style.css'

class QuestionNavigation extends React.Component {
  state = {
    filter: 'all',
  }

  setQuestionNavButton = (currentArray) => {
    const { current } = this.props
    let btnClassName
    if (current === currentArray.page) {
      btnClassName = 'button button--current button--rounded button--nav'
    } else if (currentArray.tag === 'ans') {
      btnClassName = 'button button--answered button--rounded button--nav'
    } else {
      btnClassName = 'button button--unanswered button--rounded button--nav'
    }
    return btnClassName
  }

  createColumns = (array) => {
    const { onChange, toggleQuestionNavigation } = this.props
    const columns = []

    const len = Math.ceil(array.length / 5)

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; i++) {
      const children = []
      // eslint-disable-next-line no-plusplus
      for (let j = i * 5; j < i * 5 + 5; j++) {
        if (j === array.length) {
          break
        }
        children.push(
          // eslint-disable-next-line no-loop-func
          <div
            role="presentation"
            onClick={() => {
              onChange(array[j].page)
              toggleQuestionNavigation()
            }}
          >
            <div className={this.setQuestionNavButton(array[j])}>
              Question {array[j].page}
            </div>
          </div>,
        )
      }
      columns.push(<div className="col">{children}</div>)
    }
    return columns
  }

  render() {
    const {
      showQuestionNav,
      answeredQuestions,
      unAnsweredQuestions,
    } = this.props
    const { filter } = this.state

    const allQuestions = answeredQuestions.concat(unAnsweredQuestions)
    const navLink = ['cursor-pointer nav__link']
    const allNavLink = [...navLink]
    const ansNavLink = [...navLink]
    const unAnsNavLink = [...navLink]
    if (this.state.filter === 'all') {
      allNavLink.push('active')
    }
    if (this.state.filter === 'ans') {
      ansNavLink.push('active')
    }
    if (this.state.filter === 'unAns') {
      unAnsNavLink.push('active')
    }
    let questions

    if (filter === 'all') {
      questions = allQuestions.map((question) => question)
    } else if (filter === 'unAns') {
      questions = unAnsweredQuestions.map((question) => question)
    } else if (filter === 'ans') {
      questions = answeredQuestions.map((question) => question)
    }

    questions = sortBy(questions, (q) => q.page)

    return (
      <React.Fragment>
        {showQuestionNav ? (
          <div className="question-nav">
            <div className="question-filter">
              <ul className="nav nav--pills">
                <li className="nav__item">
                  <div
                    role="presentation"
                    className={allNavLink.join(' ')}
                    onClick={() => this.setState({ filter: 'all' })}
                  >
                    All
                  </div>
                </li>
                <li>
                  <div
                    role="presentation"
                    className={unAnsNavLink.join(' ')}
                    onClick={() => this.setState({ filter: 'unAns' })}
                  >
                    {unAnsweredQuestions.length} Unanswered{' '}
                  </div>
                </li>
                <li>
                  <div
                    role="presentation"
                    className={ansNavLink.join(' ')}
                    onClick={() => this.setState({ filter: 'ans' })}
                  >
                    {answeredQuestions.length} Answered
                  </div>
                </li>
              </ul>
            </div>
            <p>
              You have <strong>{unAnsweredQuestions.length} unanswered</strong>{' '}
              questions remaining
            </p>
            <div>
              <div className="row">{this.createColumns(questions)}</div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    )
  }
}

QuestionNavigation.propTypes = {
  showQuestionNav: PropTypes.bool,
  onChange: PropTypes.func,
  current: PropTypes.number,
  answeredQuestions: PropTypes.array,
  unAnsweredQuestions: PropTypes.array,
  toggleQuestionNavigation: PropTypes.func,
}

export default QuestionNavigation
