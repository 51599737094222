import React from 'react'
import PropTypes from 'prop-types'
import DropDown from '../../assets/dropdown.svg'

const Header = ({
  title,
  toggleQuestionNavigation,
  total,
  current,
  answeredQuestions,
}) => {
  let completionPer = answeredQuestions / total
  completionPer *= 100
  completionPer = Math.floor(completionPer)
  return (
    <React.Fragment>
      <div className="modal-header">
        <div className="modal-desc">
          <h2 className="modal-title">{title}</h2>
          Quiz, Question {current}
        </div>
        {total > 1 ? (
          <React.Fragment>
            <div className="progress-group">
              {`${completionPer}% (Question ${answeredQuestions}/${total})`}
              <div className="progress">
                <div
                  className="progress__bar progress__bar--green"
                  role="progressbar"
                  style={{ width: `${completionPer}%` }}
                  aria-valuenow={completionPer}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
            <div
              type="button"
              className="button toggle-nav bg-white"
              role="presentation"
              onClick={toggleQuestionNavigation}
            >
              <img src={DropDown} alt="aa" height={16} width={16} />
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  toggleQuestionNavigation: PropTypes.func,
  total: PropTypes.number,
  current: PropTypes.number,
  answeredQuestions: PropTypes.number,
}

export default Header
