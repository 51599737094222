import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import MenuButton from 'components/ControlMenu/MenuButton'

const VideoPlaybackRateMenuButton = ({
  rates,
  className,
  setPlaybackRate,
  videoEl,
}) => {
  const items = rates.map((rate) => ({
    label: `${rate}x`,
    value: rate,
  }))

  return (
    <MenuButton
      className={classNames('video-react-playback-rate', className)}
      onSelectItem={(i) => setPlaybackRate(items[i].value)}
      items={items}
      selectedIndex={videoEl ? rates.indexOf(videoEl.playbackRate) : 0}
      title="Playback Speed"
    >
      <span className="video-react-control-text">Playback Rate</span>
      <div className="video-react-playback-rate-value">
        {`${videoEl ? videoEl.playbackRate : 1}x`}
      </div>
    </MenuButton>
  )
}

VideoPlaybackRateMenuButton.propTypes = {
  rates: PropTypes.array,
  className: PropTypes.string,
  setPlaybackRate: PropTypes.func,
  videoEl: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
}

export default VideoPlaybackRateMenuButton
