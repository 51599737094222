const getImageSrc = (content) => {
  const temp = document.createElement('div')
  const frag = document.createDocumentFragment()

  temp.innerHTML = content
  frag.appendChild(temp)
  return temp.getElementsByTagName('img')
}

export default getImageSrc
